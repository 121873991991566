<template>
    <div class="contactUS">
        <div class="contactUS-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Contact US</h5>
        </div>
        <div class="contactUS-main">
            <span>Please feel free to contact us through Email or live chat (button on bottom right hand corner) if you have any questions or concerns.</span>
            <div class="emil">
                <div>
                    <img src="@/assets/images/personal/E-mail.png" />
                    <span>E-mail:</span>
                </div>
                <u @click="EmailClick()">info@soyego.com</u>
            </div>
            <div class="emil">
                <div>
                    <img src="@/assets/images/personal/address.png" />
                    <span>Representative Office:</span>
                </div>
                <!-- <p>Flat/RM 616, 6/F , Kam Teem Industrial Bldg., 135 Connaught Road West, Sai Wan, Hong Kong</p> -->
                <p>6011 Grape Dr, Commerce City, CO 80022</p>
            </div>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
.contactUS {
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contactUS-title {
        width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 40px;

        .back {
            position: absolute;
            display: flex;
            align-items: center;
            cursor: pointer;
            left: 20px;

            span {
                font-size: 14px;
                font-family: Montserrat,SF-UI;
                font-weight: Regular;
                color: #330000;
                padding-left: 6px;
            }
        }

        h5 {
            font-size: 30px;
            font-family: Montserrat,SF-UI;
            font-weight: bold;
            color: #330000;
        }
    }

    .contactUS-main{
        width: calc(100% - 40px);
        padding: 0 20px 532px;
        display: flex;
        flex-direction: column;
        
        >span{
            font-size: 14px;
            font-family: Montserrat,SF-UI;
            font-weight: 300;
            color: #330000;
        }
        .emil{
            display: flex;
            flex-direction: column;
            padding-top: 35px;

            >div{
                display: flex;
                align-items: center;
                span{
                    padding-left: 10px;
                    font-size: 14px;
                    font-family: Montserrat,SF-UI;
                    font-weight: bold;
                    color: #330000;
                }
            }
            u{
                font-size: 16px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                color: #330000;
                cursor: pointer;
            }
            p{
                font-size: 16px;
                font-family: Montserrat,SF-UI;
                font-weight: 400;
                color: #330000;
            }
        }
    }
}
</style>

<script>
export default {
    name: "contactUS",
    components: {

    },
    data: () => {
        return {
            
        }
    },
    created() { },
    mounted() { },
    methods: {
        backClick() {
            this.$router.go(-1);
        },
        EmailClick() {
            window.open('mailto:info@soyego.com', "_blank");
        }
    }
}
</script>
